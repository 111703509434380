import React from "react";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="bg-white flex flex-col py-12">
      <div className="container mx-auto">
        <h2 className="flex font-rig text-brand-red text-6xl justify-center mb-4">
          About
        </h2>
        <div className="flex flex-col text-justify px-4 sm:px-0 gap-4 max-w-2xl mx-auto text-lg font-typewriter">
          <span className="text-center text-sm font-bold">Est. 2022</span>
          <p>
            We're serving up slices of Brooklyn history with a modern twist. We honor the craft that made Brooklyn pizza legendary, carrying on the tradition with the same dedication and passion.
          </p>
          <p>
            The menu is pure magic. We offer the classics done right – NY slices that fold perfect every time, along with the kind of Italian-American dishes that remind you of Sunday dinners when you were a little kid growing up. When it comes to comparing Brooklyn pizza to anywhere else – <i>fuhgeddaboudit</i>.
          </p>
          <p>
            Every pie coming out of our ovens pays respect to the Brooklyn pizza makers who paved the way, while adding our own signature touch. Having spent years perfecting these recipes, you can taste the difference in every bite.
          </p>
          <p>
            Come through to Sunset Park, grab a slice, and be part of something real. No gimmicks, just good pizza and good food.
          </p>
          <Link
            className="block max-w-[180px] mx-auto text-center text-white bg-brand-red py-2 px-6 font-typewriter rounded-md font-bold text-xl hover:bg-brand-red-dark transition-colors duration-200"
            to="/menu"
          >
            View Menu
          </Link>
        </div>
      </div>
    </div>
  );
};

export default About;
