import React from "react";

const VisitUs = () => {
  const locations = [
    // {
    //   location: "Bensonhurst",
    //   image: "/hero-images/bensonhurst-storefront.jpg",
    //   imageAlt: "Rob's of Brooklyn storefront of Bensonhurst location",
    //   address: {
    //     text: "8774B Bay Pkwy\nBrooklyn, NY 11214",
    //     link: "https://goo.gl/maps/wdekH6DRL5X5Kxg18"
    //   },
    //   phone: "(347) 587-5777",
    //   hours: "Monday – Sunday\n11 am – 10 pm"
    // },
    {
      location: "Sunset Park",
      image: "/hero-images/sunset-park-storefront.jpg",
      imageAlt: "Rob's of Brooklyn storefront of Sunset Park location",
      address: {
        text: "4201 7th Ave\nBrooklyn, NY 11232",
        link: "https://maps.app.goo.gl/U2CenEdKqtYtBgn99"
      },
      phone: "(347) 599-0179",
      hours: "Monday – Sunday\n11 am – 10 pm"
    }
  ];

  const LocationCard = ({ location }) => (
    <div className="flex flex-col gap-6">
      <img 
        src={location.image} 
        alt={location.imageAlt} 
        className="h-auto w-full object-cover rounded-xl max-w-[22rem] mx-auto " 
      />
      <div className="text-center font-typewriter flex flex-col gap-4">
        <div className="text-lg font-bold text-brand-red">
          {location.location}
        </div>
        <div>
          <a
            className="underline hover:text-brand-red cursor-pointer"
            href={location.address.link}
            target="_blank"
            rel="noreferrer"
          >
            {location.address.text.split('\n').map((line, i) => (
              <React.Fragment key={i}>
                {line}<br />
              </React.Fragment>
            ))}
          </a>
        </div>
        <hr className="w-8 border-2 border-brand-green mx-auto" />
        <div>
          <a
            className="underline hover:text-brand-red cursor-pointer"
            href={`tel:+1${location.phone.replace(/\D/g,'')}`}
          >
            {location.phone}
          </a>
        </div>
        <hr className="w-8 border-2 border-brand-green mx-auto" />
        <div>
          {location.hours.split('\n').map((line, i) => (
            <React.Fragment key={i}>
              {i === 0 ? <span>{line}</span> : line}<br />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="bg-brand-tan/50 py-12 px-4">
      <h2 className="font-rig text-brand-red text-6xl text-center mb-12">
        Visit Us
      </h2>
      
      <div className="grid gap-16 max-w-4xl mx-auto">
        {locations.map((location, index) => (
          <LocationCard key={index} location={location} />
        ))}
      </div>
    </div>
  );
};

export default VisitUs;